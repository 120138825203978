<template>
  <div>
    <div class="home_bg">
      <!-- <img src="../../static/image/home_bg.png" /> -->
      <el-carousel :interval="5000" height="500px" class="home_card">
        <el-carousel-item v-for="item in imgList" :key="item + 'img'">
          <div :style="'background: url(' + item + ') no-repeat center center;'" class="home_card_item"></div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home_news">
      <div class="home_news_bg">
        <img src="../../static/image/home_news_bg.jpg" />
      </div>
      <div class="home_news_title">
        <div class="" @click="getTopIndex(9)"
          :class="topIndex == 9 ? 'home_news_title_zn home_news_title_zn1' : 'home_news_title_zn'">
          新闻资讯
        </div>
        <div class="" style="margin-left: 30px;" @click="getTopIndex(20)"
          :class="topIndex == 20 ? 'home_news_title_zn home_news_title_zn1' : 'home_news_title_zn'">
          通知公告
        </div>
        <div class="home_news_title_en" @click="toPage('/newsList', { index: topIndex == 20 ? 2 : 1 })">
          MORE
        </div>
        <div class="home_news_title_line"></div>
        <div class="home_news_title_list moveTop" v-for="item in newsList"
          @click="toPage('/newsListDetail', { type: '新闻资讯', id: item.newsId })" :key="item.id">
          <div class="home_news_title_list_data">
            <p class="home_news_title_list_data_day">{{ item.day }}</p>
            <p class="home_news_title_list_data_year">{{ item.year }}</p>
          </div>
          <div class="home_news_title_list_summary">{{ item.newsTitle }}</div>
          <div class="home_news_title_list_right">
            <img src="../../static/image/home_news_right.png" width="20" height="20" />
          </div>
        </div>
      </div>
    </div>
    <div class="home_otherNews">
      <div class="home_otherNews_message">
        <div class="home_otherNews_message_title">
          <p class="home_otherNews_message_title_zn">信息披露</p>
          <p class="home_otherNews_message_title_en" @click="toPage('/newsList', { index: 0 })">
            MORE
          </p>
        </div>
        <div class="home_otherNews_message_list">
          <div class="home_otherNews_message_list_item moveTop" v-for="item in newsList1" @click="
            toPage('/newsListDetail', { type: '信息披露', id: item.newsId })
            " :key="item.id">
            <div class="home_otherNews_message_list_item_title">
              <p class="home_otherNews_message_list_item_title_day">
                {{ item.day }}
              </p>
              <p class="home_otherNews_message_list_item_title_year">
                {{ item.year }}
              </p>
            </div>
            <div class="home_otherNews_message_list_item_name">
              <p class="home_otherNews_message_list_item_name_1">
                {{ item.newsTitle }}
              </p>
              <p class="home_otherNews_message_list_item_name_2">
                发布时间：{{ item.year }} {{ item.day }}
              </p>
            </div>
          </div>
          <div class="home_otherNews_message_list_empty"></div>
        </div>
      </div>
      <div class="home_otherNews_risk">
        <div class="home_otherNews_risk_title">
          <p class="home_otherNews_risk_title_zn">风险告知书</p>
        </div>
        <div class="home_otherNews_risk_list" @click="toPage('/newsListDetail', { type: '风险告知书', id: 236 })">
          <div class="home_otherNews_risk_list_content" v-html="fxgzs">
          </div>
          <img src="../../static/image/home_risk.jpg" width="100%" />
        </div>
      </div>
      <div class="home_otherNews_buy">
        <div class="home_otherNews_buy_title">
          <p class="home_otherNews_buy_title_zn">购销指南</p>
          <p class="home_otherNews_buy_title_en" @click="toPage('/newsList', { index: 4 })">
            MORE
          </p>
        </div>
        <div class="home_otherNews_buy_list">
          <div class="home_otherNews_buy_list_item moveTop" v-for="item in newsList3" @click="
            toPage('/newsListDetail', { type: '购销指南', id: item.newsId })
            " :key="item.id">
            <div class="home_otherNews_buy_list_item_title">
              <p class="home_otherNews_buy_list_item_title_day">
                {{ item.day }}
              </p>
              <p class="home_otherNews_buy_list_item_title_year">
                {{ item.year }}
              </p>
            </div>
            <div class="home_otherNews_buy_list_item_name">
              <p class="home_otherNews_buy_list_item_name_1">
                {{ item.newsTitle }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_bg1">
      <img src="../../static/image/home_bg1.jpg" width="100%"/>
    </div>
    <div class="home_business">
      <div class="home_business_title">
        <div class="home_business_title_zn">中峪商贸</div>
        <div class="home_business_title_en" @click="toPage('/business')">
          MORE
        </div>
      </div>
      <div class="home_business_list">
        <div v-for="item in newsList4" :key="item.id" @click="toPage('/businessDetail', { id: item.newsId })"
          class="home_business_list_item moveTop">
          <div class="home_business_list_item_outside">
            <img :src="item.newsCover" width="100%" style="max-height: 280px;" />
          </div>
          <div class="home_business_list_item_content">
            {{ item.newsTitle }}
          </div>
        </div>
      </div>
    </div>
    <div class="home_introduction">
      <div class="home_introduction_bg">
        <div class="home_introduction_content">
          <div class="home_introduction_content_title">
            四川中峪拍卖交易服务有限责任公司简介
          </div>
          <div class="home_introduction_content_details">
            四川中峪拍卖交易服务有限责任公司简称“中峪数交”，为中峪集团生态内循环闭环产业链之一，具有多行业背景和资源优势，整合融通了多品种商品资源、数字化拍卖服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+数字拍卖服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为实现数字经济新农业，乡村振兴新载体发展起到强大支撑和赋能…
          </div>
          <div class="home_introduction_content_more" @click="toPage('/introduction')">
            查看详情>>
          </div>
        </div>
        <div class="home_introduction_img_out">
          <img src="../../static/image/home_introduction.jpg" />
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "index",
  data() {
    return {
      newsList: [],
      newsList1: [],
      newsList3: [],
      newsList4: [],
      topIndex: 9,
      fxgzs: '',
      imgList: [
        require("../../static/image/home_topBG1.jpg"),
        require("../../static/image/home_bg.png"),
      ]
    };
  },
  computed: {},
  mounted() {
    window.scroll(0, 0);
    this.getAllList();
    this.getWXInfo()
  },
  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: '中峪数交', // 分享标题
            desc: '中峪数交于2021年2月正式批复成立的交易市场，公司坐落于环境优化、人文底蕴浓厚的浙江绍兴市柯桥区；企业以产融孵化、促进商品流通内需、帮助企业去库存、刺激市场消费，独创全新批零交割商业架构，服务于全国各中小型实体生产企业', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-08/238df74fbbf742e2a6d652ee98b6430c.jpg', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    getAllList() {
      this.getList(9);
      this.getList(8);
      this.getList(6);
      this.getList(5);
      this.getFXGZS();
    },
    getFXGZS() {
      axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/236`,
        )
        .then(async (res) => {
          this.fxgzs = res.data.data.newsContent
        });
    },
    getTopIndex(categoryId) {
      this.topIndex = categoryId
      this.getList(categoryId)
    },
    getList(categoryId) {
      axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?status=PUBLISHED&current=1&size=10&websiteCategoryId=${categoryId}`,
        )
        .then(async (res) => {
          const content = res.data.data.list;
          content.forEach((item) => {
            const updateTime = new Date(item.publishTime);
            item.day =
              updateTime.getDate() > 9
                ? updateTime.getDate()
                : "0" + updateTime.getDate();
            item.year =
              updateTime.getFullYear() +
              "." +
              (updateTime.getMonth() + 1 > 9
                ? updateTime.getMonth() + 1
                : "0" + (updateTime.getMonth() + 1));
            item.newsTitle =
              item.newsTitle.length > 30
                ? item.newsTitle.substring(0, 30) + "..."
                : item.newsTitle;
          });
          if (categoryId == 9) {
            this.newsList = content.splice(0, 5);
          }
          if (categoryId == 20) {
            this.newsList = content.splice(0, 5);
          }
          if (categoryId == 8) {
            this.newsList1 = content.splice(0, 4);
          }
          if (categoryId == 6) {
            this.newsList3 = content.splice(0, 5);
          }
          if (categoryId == 5) {
            this.newsList4 = content.splice(0, 4);
          }
        });
    },
    toPage(path, query) {
      this.$router.push({ path, query });
    },
  },
};
</script>
<style scoped lang="scss">
.home_card {
    .home_card_item {
      width: 100%;
      height: 100%;
      background-size: cover !important;
      background-position: center;
    }
  }
.home_bg {
  border-top: 12px solid #9e1307;
  margin-top: 5px;
  min-width: 1240px;

  img {
    width: 100%;
  }
}

.home_news {
  width: 1200px;
  padding: 0 20px;
  margin: 80px auto;
  display: flex;

  .home_news_bg {
    margin-right: 24px;
  }

  .home_news_title {
    width: 100%;
    line-height: 40px;

    .home_news_title_zn {
      font-family: 宋体;
      font-size: 20px;
      font-weight: bold;
      color: #1d1b19;
      display: inline-block;
      position: relative;
      cursor: pointer;
    }


    .home_news_title_zn1::after {
      content: "";
      position: absolute;
      top: 42px;
      left: 0;
      width: 84px;
      height: 2px;
      background: #9e1307;
    }

    .home_news_title_en {
      cursor: pointer;
      font-size: 14px;
      color: #4d5461;
      float: right;
    }

    .home_news_title_line {
      width: 100%;
      height: 1px;
      background: #e5e5e5;
    }

    .home_news_title_list {
      border-bottom: 1px dashed #abaeb0;
      display: flex;
      height: 67px;
      align-items: center;
      cursor: pointer;

      .home_news_title_list_data {
        margin-right: 15px;

        .home_news_title_list_data_day {
          font-size: 16px;
          color: #9e1307;
          line-height: 0px;
          text-align: center;
        }

        .home_news_title_list_data_year {
          font-size: 8px;
          font-weight: 400;
          color: #9e1307;
          line-height: 11px;
        }
      }

      .home_news_title_list_summary {
        font-size: 14px;
        color: #1d1b19;
        line-height: 67px;
      }

      .home_news_title_list_right {
        line-height: 67px;
        margin-left: auto;
      }
    }
  }
}

.home_otherNews {
  width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  margin-bottom: 70px;
  display: flex;

  .home_otherNews_message {
    border: 1px solid #efefef;
    width: 365px;

    .home_otherNews_message_title {
      background: #f7f7f7;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;

      .home_otherNews_message_title_zn {
        font-family: 宋体;
        font-size: 20px;
        font-weight: bold;
        color: #1d1b19;
      }

      .home_otherNews_message_title_en {
        cursor: pointer;
        font-size: 14px;
        color: #4d5461;
      }
    }

    .home_otherNews_message_list {
      padding: 20px;

      .home_otherNews_message_list_item {
        border-bottom: 1px dashed #abaeb0;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        cursor: pointer;

        .home_otherNews_message_list_item_title {
          width: 42px;
          height: 44px;
          background: linear-gradient(360deg, #c74a10 0%, #9e1307 100%);
          border-radius: 4px;
          color: #ffffff;
          padding: 6px;

          .home_otherNews_message_list_item_title_day {
            text-align: center;
            font-size: 16px;
            color: #ffffff;
            line-height: 0;
          }

          .home_otherNews_message_list_item_title_year {
            text-align: center;
            font-size: 8px;
            font-weight: 400;
            color: #ffffff;
            line-height: 5px;
          }
        }

        .home_otherNews_message_list_item_name {
          margin-left: 16px;

          .home_otherNews_message_list_item_name_1 {
            width: 230px;
            font-size: 14px;
            font-weight: 400;
            color: #1d1b19;
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .home_otherNews_message_list_item_name_2 {
            font-size: 12px;
            font-weight: 400;
            color: #abaeb0;
            line-height: 17px;
          }
        }
      }

      .home_otherNews_message_list_empty {}
    }
  }

  .home_otherNews_risk {
    border: 1px solid #efefef;
    width: calc(100% - 365px - 365px);
    margin-left: 24px;

    .home_otherNews_risk_title {
      background: #f7f7f7;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;

      .home_otherNews_risk_title_zn {
        font-family: 宋体;
        font-size: 20px;
        font-weight: bold;
        color: #1d1b19;
      }

      .home_otherNews_risk_title_en {
        font-size: 14px;
        color: #4d5461;
      }
    }

    .home_otherNews_risk_list {
      padding: 20px;
      cursor: pointer;

      .home_otherNews_risk_list_content {
        font-size: 14px;
        font-weight: 400;
        color: #1d1b19;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        height: 130px;
      }

      img {
        margin-top: 20px;
      }
    }
  }

  .home_otherNews_buy {
    border: 1px solid #efefef;
    width: 365px;
    margin-left: 24px;

    .home_otherNews_buy_title {
      background: #f7f7f7;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;

      .home_otherNews_buy_title_zn {
        font-family: 宋体;
        font-size: 20px;
        font-weight: bold;
        color: #1d1b19;
      }

      .home_otherNews_buy_title_en {
        cursor: pointer;
        font-size: 14px;
        color: #4d5461;
      }
    }

    .home_otherNews_buy_list {
      padding: 20px;

      .home_otherNews_buy_list_item {
        border-bottom: 1px dashed #abaeb0;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        .home_otherNews_buy_list_item_title {
          width: 42px;
          height: 44px;
          border-radius: 4px;
          color: #ffffff;
          padding: 6px;

          .home_otherNews_buy_list_item_title_day {
            text-align: center;
            font-size: 16px;
            color: #9e1307;
            line-height: 0;
          }

          .home_otherNews_buy_list_item_title_year {
            text-align: center;
            font-size: 8px;
            font-weight: 400;
            color: #9e1307;
            line-height: 5px;
          }
        }

        .home_otherNews_buy_list_item_name {
          margin-left: 16px;

          .home_otherNews_buy_list_item_name_1 {
            font-size: 14px;
            font-weight: 400;
            color: #1d1b19;
            line-height: 20px;
            width: 230px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .home_otherNews_buy_list_item_name_2 {
            font-size: 12px;
            font-weight: 400;
            color: #abaeb0;
            line-height: 17px;
          }
        }
      }

      .home_otherNews_message_list_empty {}
    }
  }
}

.home_bg1 {
  width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  margin-bottom: 70px;
}

.home_business {
  width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  margin-bottom: 50px;

  .home_business_title {
    height: 48px;
    background: #9e1307;
    display: flex;
    color: #ffffff;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    line-height: 48px;

    .home_business_title_zn {
      font-family: 宋体;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    }

    .home_business_title_en {
      cursor: pointer;
      font-size: 14px;
      color: #c7a779;
    }
  }

  .home_business_list {
    border: 1px solid #efefef;
    display: flex;
    padding: 40px 32px;
    justify-content: space-between;

    .home_business_list_item {
      cursor: pointer;
      width: calc((100% - 32px - 32px - 32px) / 4);
      overflow: hidden;
      .home_business_list_item_outside{
        width: 100%;
        height: 150px;
        overflow: hidden;
      }

      .home_business_list_item_content {
        text-align: center;
        margin-top: 15px;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.home_introduction {
  width: 1160px;
  margin: 0 auto 50px auto;
  padding: 80px 20px;
  background: #f7f7f7;

  .home_introduction_bg {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .home_introduction_content {
    width: 560px;
    position: relative;

    .home_introduction_content_title {
      font-size: 20px;
      font-weight: bold;
      color: #1d1b19;
      line-height: 28px;
    }

    .home_introduction_content_details {
      margin-top: 20px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(86, 87, 89, 0.61);
      line-height: 20px;
      letter-spacing: 1px;
    }

    .home_introduction_content_more {
      cursor: pointer;
      width: 110px;
      height: 40px;
      background: #9e1307;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .home_introduction_img_out {
    width: 440px;
    height: 280px;
    border: 5px solid #fff;
    overflow: hidden;

    img {
      width: 440px;
      transform: translate(0, -10%);
    }

  }


}
</style>
